import { useState,useEffect } from "react";
import { CarouselItem } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { useRouter } from "next/router";
import Image from "next/image";
import { useWindowSize } from "api/commonConstants";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const DynamicBanner = (props) => {
  console.log("propsprops", props);
  const isMobile = props?.isMobile || false
  const router = useRouter();
  const [openDownloadAppMobileModal, setOpenDownloadAppMobileModal] =
    useState(false);
  const [openDownloadAppModal, setOpenDownloadAppModal] = useState(false);

  const size = useWindowSize();
  const assignRedirectionUrl = (index) => {
    if (index === 2) {
      return "/audio";
    } else if (index === 3) {
      return "/quiz/live";
    } else if (index === 4) {
      return "/short-video";
    } else {
      return "/";
    }
  };

  const [firstScroll, setFirstScroll] = useState(false);

  useEffect(() => {
    function handleScroll() {
      setFirstScroll(true)
      window.removeEventListener("scroll", handleScroll);
    }

    window.addEventListener("scroll", handleScroll);
  
  }, []);

  const handleQuiz = () => {
    // size.width > 500
    //   ? setOpenDownloadAppModal(true)
    //   : setOpenDownloadAppMobileModal(true);
    router.push("/quiz");
  };

  return (
    <>
      {props.list?.length > 0 ? (
        <Carousel
          indicators={true}
          controls={false}
          pause="hover"
          interval="5000"
        >
          {props.list?.length > 0 &&
            props.list?.map((banner) => (
              <CarouselItem key={`index_${banner.index}_${banner.title}`}>
                {!(banner.type === "LIVE") ? (
                  <div>
                    <div
                      className="bannerCarouselImage"
                      onClick={() => {
                        //console.log("banner.deepLink", banner.deepLink);
                        banner.title === "quiz"
                          ? handleQuiz()
                          : banner.deepLink.length > 0 && banner.index === 0
                          ? router.push(banner.deepLink)
                          : router.push(assignRedirectionUrl(banner?.index));
                      }}
                    >
                      {/* <img
                        src={
                          props.type === "website"
                            ? banner.bannerImage
                            : banner.mWebBannerImage
                        }
                        alt={`Banner number ${banner.index}`}
                      /> */}
                      <img
                        src={
                          props.type === "website"
                            ? `${banner.bannerImage}?width=${firstScroll ? 500 : 300}`
                            : `${banner.mWebBannerImage}?width=${firstScroll ? 500 : 295}`
                        }
                        loading="lazy"
                        alt={`Banner number ${banner.index}`}
                        height="auto"
                        width="100%"
                      />
                    </div>
                    {/* <div style={{ height: "40px", width: "100%" }}></div> */}
                  </div>
                ) : banner?.typeId?.length > 0 ? (
                  //   <a href={`/liveStreaming?postId=${banner?.typeId}`} passHref>
                  <div
                    className={"bannerIframe0"}
                    style={{ position: "relative" }}
                  >
                    <iframe
                      src={
                        props.type === "website"
                          ? banner.bannerImage
                          : banner.mWebBannerImage
                      }
                      width="100%"
                      loading="lazy"
                      title="web banner"
                    ></iframe>
                    <div
                      className="overlay-banner-election"
                      onClick={() => {
                        router.push(`/liveStreaming?postId=${banner?.typeId}`);
                      }}
                    ></div>
                    <div style={{ height: "40px", width: "100%" }}></div>
                  </div>
                ) : (
                  <div className={"bannerIframe"}>
                    <iframe
                      src={
                        props.type === "website"
                          ? banner.bannerImage
                          : banner.mWebBannerImage
                      }
                      width="100%"
                      loading="lazy"
                      title="web banner"
                    ></iframe>
                  </div>
                )}
              </CarouselItem>
            ))}
        </Carousel>
      ) : (
        <div
          className="leftImage"
          role={"button"}
          aria-label="left image"
          onClick={() => handleQuiz()}
          style={{ marginBottom: "8px" }}
        >
          <img
            src={
              "https://static.thelallantop.com/1679038971660MicrosoftTeams-image%20%289%29.png?width=400"
            }
            alt="banner-2"
            height="117px"
            // width="360px"
            loading="lazy"
            // sizes="(max-width: 1500px) 40vw,
            //         (max-width: 768px) 70vw,
            //         (max-width: 500px) 40vw
            //        "
            className="bannerImage"
          />
        </div>
      )}
      <Modal
        show={openDownloadAppModal}
        fullscreen={false}
        centered
        size="sm"
        onHide={() => setOpenDownloadAppModal(false)}
      >
        <div className="row p-2">
          <div className="col-6" style={{ fontSize: "18px" }}>
            <span>
              <FormattedMessage
                id="dowload_app"
                defaultMessage="ऐप डाउनलोड करें"
              />
            </span>
          </div>
          <div className="col-6 text-end">
            <div role={"button"} onClick={() => setOpenDownloadAppModal(false)}>
              <span className="icon-close"></span>
            </div>
          </div>
        </div>

        <Modal.Body>
          <div className="text-center">
            <div className="qr-image-container-header mb-2">
              <Image
                src="/assets/images/app_share_lallantop_compressed.webp"
                alt="Google Play"
                loading="lazy"
                className="qr-image-header"
                width="100%"
                height="100%"
                sizes="(max-width: 1500px) 100vw,
                    (max-width: 768px) 70vw,
                    (max-width: 500px) 40vw,
                    33vw
                "
              />
            </div>
            <span>
              <FormattedMessage
                id="lallantop_mobile_app"
                defaultMessage="लल्लनटॉप मोबाइल डाउनलोड करने के लिए QR कोड स्कैन करें"
              />
            </span>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={openDownloadAppMobileModal}
        fullscreen={false}
        centered
        size="sm"
        onHide={() => setOpenDownloadAppMobileModal(false)}
      >
        <div className="row p-2">
          <div className="text-end">
            <div
              role={"button"}
              onClick={() => setOpenDownloadAppMobileModal(false)}
            >
              <span className="icon-close"></span>
            </div>
          </div>
        </div>

        <Modal.Body>
          <div className="text-center">
            <div className="qr-image-container-header mb-2">
              <img
                src="/assets/images/m-logo.svg"
                alt="Lallantop logo"
                loading="lazy"
                className="logo-download-app"
                width="70"
                height="70"
              />
            </div>

            <span className="mobile_app_download_text">
              <FormattedMessage
                id="lallantop_app_download_mobile"
                defaultMessage="ताज़ा ख़बरें, वीडियो, डेली शो और किस्सों का ठिकाना"
              />
            </span>

            <br />

            <div className="mobile_app_download_btn">
              <a
                href="https://onelink.to/hsnwrm"
                className="arena-btn download-app-mobile"
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  <FormattedMessage
                    id="lallantop_app_download_mobile_btn"
                    defaultMessage="अभी डाउनलोड करें"
                  />
                </span>
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DynamicBanner;
